@import "../../style/index.scss";

.home{
  min-height: 100vh;
  background-image: url('../../assets/img/bg.jpg');
  background-repeat: repeat;
  background-position: center;
  background-color: #000;
  background-size: contain;
  position: relative;
  width: 100%;
  overflow-x: hidden;
  .header{
    position: fixed;
    top: -.05rem;
    left: 0;
    width: 100%;
    z-index: 9999;
    background: url('../../assets/img/headerBG.png') no-repeat top;
    background-size: 100%;
    // background: #000;
    padding-top: .4rem;
    padding-bottom: .5rem;
    &-container{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 1rem;
      padding-right: 2.36rem;
      height: 100%;
      .logo{
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          height: .61rem;
        }
      }
      .nav{
        ul{
          display: flex;
          margin: 0 -0.34rem;
          li{
            color: rgb(255, 255, 255, 70%);
            font-size: .3rem;
            padding: 0 .34rem;
            font-family: 'HarmonyOS-Bold';
            .header-nav-item{
              cursor: pointer;
              &.active,&:hover{
                color: #fff;
              }
            }
          }
        }
       
      }
    }
  }
  .topBg{
    position: absolute;
    left: 0;
    top: .5rem;
    height: 10.65rem;
    width: 100%;
    background: url('../../assets/img/top_bg.png') no-repeat center;
    background-size: cover;
  }
  .body{
    position: relative;
   
    .part1{
      position: relative;
      padding-top: 1.7rem;
      li{
        display: flex;
        align-items: center;
        justify-content: center;
        .img{
          .bg{
            .gif_player,.gif_player img{
              height: 100%;
            }
            .gif_player{
              display: flex;
              align-items: center;
              justify-content: flex-end;
            }
          }
        }
        .text{
          color: #3D3D3D;
          .title{
            font-family: 'BM';
            font-size: .5rem;
            line-height: .784rem;
          }
          span{
            font-family: 'BM';
          }
          p{
            // font-family: 'HarmonyOS-Regular';
            font-family: 'AvenirNext-Medium';
            font-size: .36rem;
            line-height: 1.78;
          }
        }
        &.even{
          .img{
            order: 3;
          }
        }
        &.item1{
          padding-bottom: 1.6rem;
          padding-top: 1.3rem;
          .img{
            .bg{
              padding-right: 1rem;
              background: url('../../assets/img/p1-bg.png') no-repeat center;
              background-size: contain;
              width: 8.40rem;
              height: 5.56rem;
              .gif_player img{
                margin-top: 1rem;
              }
            }
          }
          .text{
            padding-right: 2.36rem;
            padding-left: 1rem;
            .title{
              width: 8.52rem;
              height: 2.88rem;
              background: url('../../assets/img/p1_title.png') no-repeat center;
              background-size: 100% 100%;
              padding-bottom: .65rem;
              padding-left: .5rem;
              display: flex;
              align-items: flex-end;
             
            }

          }
        }
        &.item2{
          margin-right: 2.36rem;
          .text{
            padding-left: 2rem;
            .title{
              margin-bottom: .3rem;
            }
          }
          .img{
            // width: 6.91rem;
           

            .bg{
              background: url('../../assets/img/p2-bg.png') no-repeat bottom right;
              background-size: 5.91rem 4.93rem;
              width: 5.91rem;
              margin-left: 1rem;
              // height: 4.93rem;
              // padding-bottom: 1rem;
              .gif_player{
                // justify-content: center;
              }
              .gif_player img{
                // margin-top: 1.3rem;
                // margin-right: .6rem;
                height: auto;
                width: 100%;
              }
            }
          }
          .mt-40{
            margin-top: .4rem;
          }
        }
        &.item3{
          margin-left: 1.26rem;
          // padding-bottom: 2.78rem;
          padding-top: 1.5rem;
          .img{
            .bg{
              background: url('../../assets/img/p3-bg.png') no-repeat left bottom;
              background-size: 4.19rem 5.09rem;
              width: 6.83rem;
              height: 6.7rem;
              .gif_player {
                img{
                  width: 5.03rem;
                  height: auto;
                }
              }
            }
          }
          .text{
            padding-right: 2.36rem;
            padding-left: 1rem;
            .title{
              padding-bottom: .5rem;
            }
          }
        }
        &.item4{
          padding-bottom: 2rem;
          padding-top: 1.5rem;
          .text{
            padding-left: 2rem;
            flex: 1;
            .title{
              margin-bottom: .3rem;
            }
          }
          .img{
            .bg{
              width: 7.34rem;
              height: 5.86rem;
              margin-right: 2.36rem;
              background: url('../../assets/img/p4-bg.png') no-repeat right bottom;
              background-size: 5.63rem 5.27rem;
              .gif_player {
                justify-content: flex-start;
                padding-left: .3rem;
              }
            }
          }
          .mt-40{
            margin-top: .4rem;
          }
        }
      }
    }
    .Products{
      padding: 1.5rem 2rem;
      .tit{
        font-family: 'BM';
        font-size: .56rem;
        line-height: 1.2;
        color: #3D3D3D;
      }
      .list{
        .item{
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          padding: 1.2rem 0;
          .img{
            width: 6.8087rem;
            .img_con{
              padding-left: .55rem;
              padding-bottom: .4rem;
              padding-top: .7rem;
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
              &::after{
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: url('../../assets/img/Products_border.png') no-repeat bottom left;
                background-size: 100% 100%;
              }
            }
          }
          .text{
            flex: 1;
            padding-left: 1.17rem;
            .title{
              font-family: 'BM';
              font-size: .48rem;
              line-height: .672rem;
              color: #000;
              display: inline-block;
              position: relative;
              margin-bottom: .3rem;
              span{
                position: relative;
                z-index: 2;
              }
              &::after{
                content: '';
                position: absolute;
                width: 1.83rem;
                height: .38rem;
                background: #BFFB07;
                left: 100%;
                bottom: 0;
                transform: translate(-50%, 0%);
              }
            }
            .info{
              font-family: 'AL-Regular';
              font-size: .36rem;
              line-height: .504rem;
              color: #000;
              margin-bottom: .2rem;
              font-weight: 100;
            }
            .des{
              font-family: 'AL-Regular';
              font-size: .24rem;
              line-height: .336rem;
              color: #000;
              margin-bottom: .3rem;
              font-weight: 100;
            }
            .lastText{
              font-family: 'BM';
              font-size: .40rem;
              line-height: .56rem;
              color: #000;
              .inner{
                padding: .2rem .24rem;
                background: url('../../assets/img/textBg.png') no-repeat center;
                background-size: 100% 100%;
                display: inline-block;
              }
            }
          }
          &::after{
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: .11rem;
            background: url('../../assets/img/prod_end_border.png') no-repeat center;
            background-size: 100% 100%;
          }
        }
        .item:first-child{
          padding-top: .4rem;
        }
        .item:last-child{
          padding-bottom: 1rem;
          .img{
            .img_con{
              img{
                width: 4.65rem;
                margin-top: -1rem;
                margin-bottom: .6rem;
              }
            }
          }
          .des{
            height: .6rem;
          }
          .title{
            img{
              width: 1.99rem;
            }
            &::after{
              display: none;
            }
          }
          &::after{
            display: none;
          }
        }
      }
    }
    .Partners{
      padding: 0 2rem;
      .tit{
        font-family: 'BM';
        font-size: .56rem;
        line-height: 1.2;
        color: #3D3D3D;
      }
      .list{
        display: grid;
        grid-template-columns: calc(33.33% - 1rem) calc(33.33% - 1rem) calc(33.33% - 1rem);
        grid-column-gap: 1rem;
        margin-right: -1rem;
        padding-top: 1rem;
        .item{
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .join{
      padding: 0 2rem;
      padding-top: 1.5rem;
      margin-bottom: 3rem;
      position: relative;
      z-index: 2;
      .tit{
        font-family: 'BM';
        font-size: .56rem;
        line-height: 1.2;
        color: #3D3D3D;
        // text-align: center;
      }
      .joinBtn{
        .btn{
          border: none;
          background: #BFFB07;
          display: flex;
          max-width: 4.5rem;
          align-items: center;
          justify-content: center;
          line-height: 1;
          padding: .3rem .8rem;
          font-family: 'HarmonyOS-Bold';
          font-size: .32rem;
          margin-top: 1rem;
          img{
            width: .67rem;
            margin-right: .15rem;
          }
        }
      }
      .buttonGroup{
        display: flex;
        align-items: center;
        justify-content: center;
        .btn{
          width: 4.34rem;
          height: 1.9rem;
          border: none;
          background: #fff;
          box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
          border-radius: 1rem;
          margin: .5rem .84rem;
        }
      }
    }
  }
  .bottomBg{
    position: absolute;
    left: 0;
    bottom: 0rem;
    height: 10.65rem;
    width: 100%;
    background: url('../../assets/img/bottom_bg.png') no-repeat center;
    background-size: cover;
  }
  .footer{
    // background: url('../../assets/img/footerBG.png') no-repeat center;
    // background-size: 100% auto;
    height: 1.6rem;
    position: relative;
    background-color: rgba(0,0,0,5%);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #626262;
    font-size: .24rem;
    line-height: 1;
  }
}


.spec{
  margin: 0 2rem;
}

// @media screen and (min-width: 1400px) {
//   .home{
//     .body{
//       .part1{
//         padding-top: .7rem;
//         li{
//           min-height: 100vh;
//           // &.item{
//           //   padding-top: 0;
//           // }
//         }
//       }
//     }
//   }
// }

@media screen and (max-width: 800px) {
  .home{
    .header{
      background-color: #000;
      &-container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 0;
        padding: 0 1rem;
        height: 100%;
        .logo{
          img{
            height: 1.4rem;
          }
        }
        .nav{
          ul{
            display: flex;
            margin: 0 -0.34rem;
            li{
              font-size: .68rem;
              padding: 0 .4rem;
              .header-nav-item{
                cursor: pointer;
              }
            }
          }
         
        }
      }
    }
    .body{
      .part1{
        li{
          flex-direction: column;
          .text{
            order: 2;
            .title{
              font-size: 1rem;
              line-height: 1.784rem;
            }
            p{
              font-size: .8rem;
            }
          }
          .img{
            order: 1;
            
          }
          &.item{
            .img{
              .bg{
                width: auto;
                height: auto;
                background-size: contain;
                padding: 1rem;
                margin: 0 2rem;
                img{
                  width: 100%;
                }
              }
            }
            
          }
          &.even{
            .img{
              order: 1;
            }
          }
          &.item1{
            padding-bottom: 1.6rem;
            padding-top: 1.3rem;
            .img{
              .bg{
              
                .gif_player img{
                }
              }
            }
            .text{
              padding-right: 2rem;
              padding-left: 2rem;
              .title{
                width: 100%;
                height: 4.88rem;
                
                
                padding-bottom: .8rem;
                padding-left: .8rem;
                display: flex;
                align-items: flex-end;
                
              }

            }
          }
          &.item2{
            margin-right: 0;
            .text{
              margin-top: 1rem;
              padding-right: 2rem;
              padding-left: 2rem;
            }
          }
          &.item3{
            margin-left: 0;
            .img{
              .bg{
                background-size: 12.19rem 15.09rem;
                .gif_player{
                  img{
                    width: 100%;
                  }
                }
              }
            }
            .text{
              padding-right: 2rem;
              padding-left: 2rem;
              margin-top: 1rem;
            }
          }
          &.item4{
            padding-top: 0;
            .img{
              .bg{
                background-position: 84% 70%;
                margin-left: -0.5rem;
                background-size: 75%;
              }
            }
            .text{
              padding-right: 2rem;
              padding-left: 2rem;
              margin-top: 1rem;
            }
          }
        }
      }
      .Products{
        .tit{
          font-size: 1.4rem;
          line-height: 1.2;
        }
        .list{
          .item{
            flex-direction: column;
            .img{
              width: 100%;
              .img_con{
                &::after{
                  background-size: 100% 102%;
                  background-position-x: -0.3rem;
                  background-position-y: 0;
                }
              }
            }
            .text{
              padding-left: 0;
            
              .title{
                font-size: 1.2rem;
                line-height: 1.2;
                margin-top: 1.5rem;
                &::after{
                  width: 3.6rem;
                  height: .7rem;
                }
              }
              .info{
                font-size: 1rem;
                line-height: 1.2;
              }
              .des{
                font-size: .7rem;
                line-height: 1.4;
                margin-top: .8rem;
              }
              .lastText{
                font-size: .8rem;
                line-height: 1.2;
                margin-top: 1.2rem;
                .inner{
                  padding: 0.4rem;
                }
              }
            }
            &:last-child {
              .img .img_con img{
                width: 100%;
              }
              .title img {
                width: 4.99rem;
              }
              .des{
                height: 0.1rem;
              }
            }
          }
        }
      }
      .join{
        .tit{
          font-size: 1.4rem;
          line-height: 1.2;
        }
        .joinBtn{
          .btn{
            border: none;
            background: #BFFB07;
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 1;
            max-width: 10rem;
            padding: .8rem 0rem;
            font-family: 'HarmonyOS-Bold';
            font-size: .8rem;
            margin-top: 2.4rem;
            color: #000;
            img{
              width: 1.5rem;
              margin-right: .4rem;
            }
          }
        }
      }
    }
    .footer{
      height: 3.2rem;
      font-size: .4rem;
    }
  }
}