@font-face {
  font-family: SAB;
  src: url('../../assets/fonts/Sansation_Bold.ttf');
  font-display: fallback;
}

@font-face {
  font-family: SAR;
  src: url('../../assets/fonts/Sansation_Regular.ttf');
  font-display: fallback;
}

@font-face {
  font-family: SFB;
  src: url('../../assets/fonts/SFProText-Bold.ttf');
  font-display: fallback;
}

@font-face {
  font-family: SFL;
  src: url('../../assets/fonts/SFProText-Light.ttf');
  font-display: fallback;
}

@font-face {
  font-family: SFM;
  src: url('../../assets/fonts/SFProText-Medium.ttf');
  font-display: fallback;
}

@font-face {
  font-family: SP75;
  src: url('../../assets/fonts/Shapiro-75HeavyText.otf') format("opentype");
  font-display: fallback;
}

@font-face {
  font-family: SFR;
  src: url('../../assets/fonts/SF-Pro-Text-Regular.otf') format("opentype");
  font-display: fallback;
}


@font-face {
  font-family: BM;
  src: url('../../assets/fonts/bm-hanna/BM-HANNA.ttf');
}

@font-face {
  font-family: HarmonyOS-Regular;
  src: url('../../assets/fonts/HarmonyOS_Sans_SC/HarmonyOS_Sans_SC_Regular.ttf');
}

@font-face {
  font-family: HarmonyOS-Bold;
  src: url('../../assets/fonts/HarmonyOS_Sans_SC/HarmonyOS_Sans_SC_Bold.ttf');
}

@font-face {
  font-family: AvenirNext-Heavy;
  src: url('../../assets/fonts/AvenirNext/AvenirNext-Heavy-09.ttf');
}

@font-face {
  font-family: AvenirNext-Medium;
  src: url('../../assets/fonts/AvenirNext/AvenirNext-Medium-06.ttf');
}


@font-face {
  font-family: AL-Regular;
  src: url('../../assets/fonts/AlibabaSans/AlibabaSans-Nornal/AlibabaSans-Regular.otf') format("opentype");
}